<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="row px-3 py-3">
            <div class="row items-start">
              <div
                class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest"
              >
                <div class="col-4 py-4 items-start">
                  <img
                    v-if="item.image_link"
                    :src="item.image_link"
                    class="w-75px h-75px object-cover primary-border-1px border-15px"
                  />
                </div>
                <div class="col-8 items-end">
                  <p>
                    <strong>{{ $t("invoice.created_user") }}</strong> :
                    {{ item.created_user ? item.created_user.name : "" }}
                  </p>
                  <p>
                    <strong>{{ $t("invoice.date") }}</strong> :
                    {{ item.created_at | momentDateFormat }}
                  </p>
                  <p>
                    <strong>{{ $t("invoice.company_name") }}</strong> :
                    <custom-multi-select
                      :item-per-row="1"
                      :max="1"
                      :model.sync="item.supplier_company_id"
                      :not-list="true"
                      :options="options.companyOptions"
                      :required="true"
                      :title="$t('invoice.company_name')"
                      name="invoice_company_name"
                    ></custom-multi-select>
                  </p>
                </div>
              </div>

              <div
                class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest"
              >
                <div class="col-12">
                  <p>
                    <strong>{{ $t("invoice.invoice_number") }}</strong> :
                    {{ item.invoice_no }}
                  </p>
                </div>
                <div class="col-12">
                  <p>
                    <strong>{{ $t("invoice.invoice_date") }}</strong> :
                    {{ item.invoice_date | momentDateFormat }}
                  </p>
                </div>
                <div class="col-12 d-lg-none d-md-block">
                  <p>
                    <strong>{{ $t("invoice.vat") }}</strong> :
                    {{ item.calculated_vat | priceFormat }}
                  </p>
                </div>

                <div class="col-12 d-flex justify-content-start">
                  <text-input
                    :disabled="true"
                    :is-inline="false"
                    :model="item.invoice_type.translations[0].name"
                    :title="$t('invoice.invoice_type')"
                  ></text-input>

                  <text-input
                    :disabled="true"
                    :is-inline="false"
                    :model="item.invoice_category.translations[0].name"
                    :title="`Tipi`"
                  ></text-input>
                </div>

                <div v-if="item.parent_invoice" class="col-12">
                  <text-input
                    :disabled="true"
                    :model.sync="item.parent_invoice.invoice_no"
                    :required="true"
                    :title="$t('invoice.parent_name_invoice')"
                  ></text-input>
                </div>
              </div>
              <div
                class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest"
              >
                <div class="col-12 d-lg-flex d-md-none">
                  <div class="col-8 d-lg-block d-md-none">
                    <p>
                      <strong>{{ $t("invoice.vat") }}</strong> :
                      {{ item.calculated_vat | priceFormat }} {{ invoiceCurrency }}
                    </p>
                    <p>
                      <strong>{{ $t("order.discount") }}</strong> :
                      {{ item.total_discount | priceFormat }} {{ invoiceCurrency }}
                    </p>
                    <p>
                      <strong>{{ $t("invoice.total") }}</strong> :
                      {{ item.total_amount | priceFormat }} {{ invoiceCurrency }}
                    </p>
                    <p>
                      <strong>{{ $t("invoice.amount_to_be_paid") }}</strong> :
                      {{ item.amount_to_be_paid | priceFormat }} {{ invoiceCurrency }}
                    </p>
                  </div>
                  <div class="col-4 d-lg-block ml-0 pl-0">
                    <qrcode-vue
                      :size="100"
                      :value="item.qr_code_link"
                      renderAs="svg"
                    ></qrcode-vue>
                    <span>{{ item.code }}</span>
                  </div>
                </div>
              </div>
              <div
                class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest"
                v-if="item.accounting_description && item.accounting_description != null"
              >
                <p>
                  <strong>{{ $t("invoice.accounting_description") }}</strong> :
                  {{ item.accounting_description }}
                </p>
              </div>
              <div class="col-12 row d-flex ml-2" v-if="item.reflect_invoice.length > 0">
                <div class="col-12 col-md-4 col-sm-12">
                  <p>
                    <strong>{{ $t("invoice.generated_reflection_invoices") }}</strong>
                  </p>
                  <p v-for="(reflectInvoice, index) in item.reflect_invoice" :key="index">
                    <span v-if="reflectInvoice.invoice_no"
                      >{{ reflectInvoice.invoice_no }}
                      <i
                        class="fa fa-eye"
                        role="button"
                        @click="redirectToInvoiceDetails(reflectInvoice.id)"
                      >
                      </i>
                    </span>
                  </p>
                </div>
                <div class="col-12 col-md-4 col-sm-12">
                  <p>
                    <strong>{{ $t("invoice.pending_reflection_invoices") }}</strong>
                  </p>
                  <p v-for="(reflectInvoice, index) in item.reflect_invoice" :key="index">
                    <span v-if="reflectInvoice.code"
                      >{{ $t("invoice.code") }}: {{ reflectInvoice.code }}
                      <i
                        class="fa fa-eye"
                        role="button"
                        @click="redirectToInvoiceDetails(reflectInvoice.id)"
                      >
                      </i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="relative">
              <div class="w-100 px-5 py-3 h-50vh">
                <div class="w-100 col-12 border-15px navy-blue-border-1px mx-3 mt-5 mb-1">
                  <table class="table w-100 border-15px mb-0">
                    <thead class="w-100">
                      <tr>
                        <th
                          class="navy-blue-border-right-1px navy-blue-border-bottom-1px"
                        >
                          <div class="text-right text-lg pr-10">
                            {{ $t("invoice.code") }}
                          </div>
                        </th>
                        <th
                          class="navy-blue-border-right-1px navy-blue-border-bottom-1px"
                        >
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t("invoice.code_name") }}
                            <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th
                          class="navy-blue-border-right-1px navy-blue-border-bottom-1px"
                        >
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t("invoice.order_number") }}
                          </div>
                        </th>
                        <th
                          class="navy-blue-border-right-1px navy-blue-border-bottom-1px"
                        >
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t("invoice.amount") }}
                            <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th
                          class="navy-blue-border-right-1px navy-blue-border-bottom-1px"
                        >
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t("invoice.unit") }}
                            <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th
                          class="navy-blue-border-right-1px navy-blue-border-bottom-1px"
                        >
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t("invoice.unit_price") }}
                            <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th
                          class="navy-blue-border-right-1px navy-blue-border-bottom-1px"
                        >
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t("invoice.vat_amount") }}
                            <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>

                        <th
                          class="navy-blue-border-right-1px navy-blue-border-bottom-1px"
                        >
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t("invoice.withholding_amount") }}
                            <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th
                          class="navy-blue-border-right-1px navy-blue-border-bottom-1px"
                        >
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t("invoice.index_amount") }}
                            <info-tooltip
                              :helper-text="$t('invoice.withholding_extra_text')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th
                          class="navy-blue-border-right-1px navy-blue-border-bottom-1px"
                        >
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t("invoice.amount_total") }}
                            <info-tooltip
                              :helper-text="$t('invoice.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>

                        <th class="navy-blue-border-bottom-1px">
                          <div class="d-flex justify-content-center items-center text-lg">
                            {{ $t("order.description") }}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="navy-blue-bottom-1px">
                      <template v-for="(item, key) in item.transactions">
                        <tr>
                          <td class="navy-blue-border-right-1px">
                            <input
                              v-model="item.line_code"
                              :disabled="true"
                              class="form-control d-inline-block mb-5"
                              type="text"
                            />
                          </td>

                          <td class="navy-blue-border-right-1px items-start">
                            <input
                              v-model="item.line_code_name"
                              :disabled="true"
                              class="form-control d-inline-block mb-5"
                              type="text"
                            />
                          </td>
                          <td class="navy-blue-border-right-1px items-start">
                            <input
                              v-if="item.waybillable"
                              v-model="item.waybillable.order_number"
                              :disabled="true"
                              class="form-control d-inline-block mb-5"
                              type="text"
                            />
                          </td>

                          <td class="navy-blue-border-right-1px items-start">
                            <input
                              v-model="item.amount"
                              class="form-control d-inline-block mb-5"
                              type="number"
                            />
                          </td>

                          <td class="navy-blue-border-right-1px items-start">
                            <custom-multi-select
                              :disabled="true"
                              :input-width="`65px`"
                              :is-inline="false"
                              :item-per-row="1"
                              :max="1"
                              :model.sync="item.amount_unit_id"
                              :not-list="true"
                              :options="options.unitOptions"
                              :required="false"
                              :title="null"
                              name=""
                            ></custom-multi-select>
                          </td>
                          <td class="navy-blue-border-right-1px">
                            <input
                              v-model="item.unit_price"
                              :disabled="true"
                              class="form-control form-control-sm d-inline-block"
                              step="1"
                              type="number"
                            />
                          </td>
                          <td class="navy-blue-border-right-1px">
                            {{ item.vat_price | priceFormat }}
                          </td>
                          <td class="navy-blue-border-right-1px">
                            {{ item.withholding_amount | priceFormat }}
                          </td>
                          <td class="navy-blue-border-right-1px">
                            {{ item.price | priceFormat }}
                          </td>
                          <td class="navy-blue-border-right-1px">
                            {{ item.total_price | priceFormat }}
                          </td>
                          <td class="">
                            <input
                              v-model="item.description"
                              class="form-control form-control-sm"
                              type="text"
                            />
                            <span></span>
                          </td>
                        </tr>
                        <tr
                          v-if="item.divided_transaction_id"
                          style="background-color: bisque"
                        >
                          <td colspan="11" class="text-center">
                            {{ findItem(item.divided_transaction_id) }}
                            {{ $t("invoice.divided_item") }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="11">
                            <div
                              class="row justify-content-center align-items-center"
                              style="flex-direction: row; display: contents"
                            >
                              <div class="col-12" v-if="item.divided_products">
                                <div class="row d-flex justify-content-center">
                                  <div class="col-md-2 col-12">
                                    <div class="text-left text-md pr-10">
                                      {{ $t("invoice.code") }}
                                    </div>
                                    <input
                                      class="form-control form-control-sm d-inline-block mb-3"
                                      v-model="item.divided_products.line_code"
                                      :disabled="true"
                                      type="text"
                                    />
                                  </div>
                                  <div class="col-md-2 col-12">
                                    <div class="text-left text-md pr-10">
                                      {{ $t("invoice.code_name") }}
                                    </div>
                                    <input
                                      class="form-control form-control-sm d-inline-block mb-3"
                                      v-model="item.divided_products.line_code_name"
                                      :disabled="true"
                                      type="text"
                                    />
                                  </div>
                                  <div
                                    class="col-md-2 col-12"
                                    v-if="item.divided_products.orders"
                                  >
                                    <custom-multi-select
                                      :has-bottom-margin="false"
                                      :is-inline="false"
                                      :max="1"
                                      :model.sync="item.divided_products.order_id"
                                      :not-list="true"
                                      :options="item.divided_products.orders"
                                      :title="$t('waybill.order_no')"
                                      input-width="150px"
                                      name="divided_product_order_id"
                                      object-key="name"
                                    ></custom-multi-select>
                                  </div>
                                  <div
                                    class="col-md-2 col-12"
                                    v-if="item.divided_products.order_id != null"
                                  >
                                    <custom-multi-select
                                      :has-bottom-margin="false"
                                      :is-inline="false"
                                      :max="1"
                                      :model.sync="item.divided_products.fabric_id"
                                      :not-list="true"
                                      :options="
                                        item.divided_products.orders[
                                          item.divided_products.order_id
                                        ].detail
                                      "
                                      :title="$t('waybill.order_no')"
                                      input-width="150px"
                                      name="divided_product_order_id"
                                      object-key="color"
                                    ></custom-multi-select>
                                  </div>
                                  <div
                                    v-if="item.divided_products.fabric_id"
                                    class="col-xl-2 col-lg-4 col-md-4 col-sm-12"
                                  >
                                    <number-input
                                      :disabled="true"
                                      :is-inline="false"
                                      :is-valid="
                                        item.divided_products.transaction_amount
                                          ? true
                                          : null
                                      "
                                      :model="
                                        item.divided_products.orders[
                                          item.divided_products.order_id
                                        ].detail[item.divided_products.fabric_id].amount
                                      "
                                      :placeholder="
                                        $t('waybill.please_enter_order_amount')
                                      "
                                      :title="$t('waybill.order_amount')"
                                      name="divided_product_color_number"
                                      step="1"
                                    ></number-input>
                                  </div>
                                  <div
                                    class="col-xl-2 col-lg-4 col-md-4 col-sm-12"
                                    v-if="item.divided_products.fabric_id"
                                  >
                                    <number-input
                                      :disabled="is_submitting"
                                      :is-inline="false"
                                      :max="item.divided_products.parent_amount"
                                      :min="0"
                                      :model.sync="
                                        item.divided_products.transaction_amount
                                      "
                                      :name="`waybill_bill_dividend%${key}-${dp_index}`"
                                      :step="1"
                                      :title="$t('waybill.amount_to_be_shred')"
                                    ></number-input>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 d-flex justify-content-center">
                                <button-with-icon
                                  :icon-name="null"
                                  :text="$t('waybill.divide_product').toUpperCase()"
                                  class="mx-1 font-weight-boldest"
                                  size="sm"
                                  @onClick="divideProduct(key)"
                                ></button-with-icon>

                                <button-with-icon
                                  v-if="item.divided_products"
                                  :text="$t('general.give_up').toUpperCase()"
                                  class="mx-1"
                                  icon-name="icons.waybill.give_up"
                                  size="sm"
                                  @onClick="cancelDividedProduct(key)"
                                ></button-with-icon>

                                <button-with-icon
                                  v-if="
                                    item.divided_products &&
                                    item.divided_products.fabric_id
                                  "
                                  :disabled="
                                    Number(item.divided_products.transaction_amount) <=
                                      0 ||
                                    Number(item.divided_products.transaction_amount) >
                                      Number(item.divided_products.parent_amount) ||
                                    Number(item.divided_products.transaction_amount) >
                                      Number(
                                        item.divided_products.orders[
                                          item.divided_products.order_id
                                        ].detail[item.divided_products.fabric_id].amount
                                      )
                                  "
                                  :text="$t('general.save').toUpperCase()"
                                  class="mx-1"
                                  icon-name="icons.waybill.save"
                                  size="lg"
                                  @onClick="submitDividedProduct(key)"
                                ></button-with-icon>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>

                <div
                  class="float-right mb-n20 w-50 lg:w-35 md:w-50 sm:w-50 py-3 px-5 border-15px navy-blue-border-1px bg-white"
                  style="bottom: 0; right: 0"
                >
                  <invoice-layout
                    :buying-type-options="options.receiveTypeOptions"
                    :buying_type_id.sync="item.invoice_receiving_type_id"
                    :currency-options="options.currencyOptions"
                    :currency-rates="currencyRates"
                    :currency_unit_id.sync="item.currency_unit_id"
                    :discount.sync="item.total_discount"
                    :exchange.sync="item.exchange_rate"
                    :item="item"
                    :transactions="item.transactions"
                    :vat-options="vatRateOptions"
                    :vat-rates="vat_rates"
                  ></invoice-layout>
                </div>
              </div>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextInput from "@/assets/components/inputs/TextInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import NumberInput from "@/assets/components/inputs/NumberInput";
import FileInput from "@/assets/components/inputs/FileInput";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import SelectInput from "@/assets/components/inputs/SelectInput";
import InvoiceLayout from "@/view/pages/invoice/layouts/InvoiceLayout";
import { mapGetters, mapMutations } from "vuex";
import QrcodeVue from "qrcode.vue";
import _ from "lodash";
import moment from "moment";
import {
  LARAVEL_DATE_FORMAT,
  LARAVEL_DATE_TIME_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
} from "@/core/config/constant";

import {
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  LOADING,
  SET_LOADING,
  RESET_VALUES,
  DELETE_ITEM_BY_ID,
  CREATE_ITEM,
} from "@/core/services/store/REST.module";
export default {
  name: "InvoiceEditV2",
  data() {
    return {
      item: null,
      itemId: null,
      currencyRates: [],
      defaultCurrency: "TL",
      options: {
        instructionTypeOptions: [],
        waybillableOptions: [],
        companyOptions: [],
        entryTypeOptions: {
          0: this.$t("invoice.enter_manually"),
          1: this.$t("invoice.scan_qr_code"),
        },
        invoiceTypeOptions: [],
        invoiceCategoryOptions: [],
        vatOptions: [],
        vatRateOptions: [],
        currencyOptions: [],
        receiveTypeOptions: {},
        waybills: [],
        unitOptions: [],
        vatDeducts: [],
        vatExceptions: [],
      },
      items: { data: [] },
      tmpItem: null,
      form: {
        is_withholding: "0",
        expense_type_id: null,

        file_names: ["image"],
        waybillable_id: null,
        supplier_company_id: null,
        invoice_type_id: null,
        invoice_category_id: null,
        invoice_kind_id: null,
        invoice_no: null,
        vat_id: 1,
        image: null,
        invoice_date: moment().format(LARAVEL_DATE_FORMAT),
        waybill_ids: [],
        currency_unit_id: "0",
        invoice_receiving_type_id: "0",
        exchange_rate: null,

        total_amount_including_taxes: 11,
        amount_to_be_paid: 11,
        total_discount: 11,
        calculated_vat: 11,
        total_amount: 11,
        withholding_amount: 11,
        withholding_amount_calculated_vat: 11,
      },
    };
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    vatRateOptions() {
      let options = this.options.vatOptionsRaw || {};
      let data = options.filter((item) => +item.type_id === 4);
      return this.convertArrayToObjectByKey(data, "id", "vat.rate");
    },
    invoiceCurrency() {
      if (this.form.currency_unit_id) {
        return this.options.currencyOptions[this.form.currency_unit_id];
      }
      return "";
    },
    formatedInvoiceHistory() {},
    transactionVatPrice() {
      return function (key) {
        let self = this;
        let rate = self.options.vatOptions.find(
          (item) => +item.id === +self.form.vat_rate_id
        ).vat.rate;
        return (self.transactionPrice(key) * rate) / 100;
      };
    },
    vat_rates() {
      let self = this;
      return _.map(_.groupBy(self.item.transactions, "vat_rate_id"), (o, idx) => {
        let vatRate = self.options.vatOptions[idx];
        return {
          rate: vatRate,
          sum: _.sumBy(o, "vat_price"),
        };
      });
    },
    transactionPrice() {
      return function (key) {
        let self = this;
        return (
          self.item.transactions[key].amount * self.item.transactions[key].unit_price
        );
      };
    },
    transactionTotalPrice() {
      return function (key) {
        return this.transactionPrice(key) + this.transactionVatPrice(key);
      };
    },
    calculated_vat() {
      let sum = 0;
      let self = this;
      this.item.transactions.forEach((item) => {
        let rate = self.options.vatOptions.find(
          (item) => +item.id === +self.item.vat_rate_id
        ).vat.rate;
        sum += (item.amount * item.unit_price * rate) / 100;
      });
      return sum;
    },
    total_amount() {
      return this.item.total_amount_including_taxes;
    },
    total_discount() {
      return this.item.total_discount;
    },
    total() {
      let sum = 0;
      this.item.transactions.forEach((item) => {
        sum += item.amount * item.unit_price;
      });
      return sum;
    },
  },
  components: {
    InvoiceLayout,
    SelectInput,
    FileInput,
    NumberInput,
    ButtonWithIcon,
    DatePickerInput,
    CustomMultiSelect,
    TextInput,
    DashboardBox,
    InfoTooltip,
    QrcodeVue,
  },
  mounted() {
    if (
      this.isUserGranted("Staff", ["invoiceDivisionAuthorization"], false, true) == false
    ) {
      return false;
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("invoice.invoices"), route: "invoice.index" },
      { title: this.$t("invoice.edit") },
    ]);

    if (!this.isUserGranted("Invoice", ["view", "edit"])) {
      return false;
    }

    let self = this,
      promises = [];
    promises.push(this.$store.dispatch(GET_ITEMS, { url: "api/units" }));
    promises.push(
      this.$store.dispatch(GET_ITEMS, {
        url: `api/supplier-companies`,
        filters: { all: true },
      })
    );
    promises.push(this.$store.dispatch(GET_ITEMS, { url: `api/currency` }));
    promises.push(this.$store.dispatch(GET_ITEMS, { url: `api/invoice-type` }));
    promises.push(this.$store.dispatch(GET_ITEMS, { url: `api/invoice-category` }));
    promises.push(this.$store.dispatch(GET_ITEMS, { url: `api/invoice-receive-type` }));
    promises.push(this.$store.dispatch(GET_ITEMS, { url: "api/vat-rates" }));
    promises.push(
      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
        url: `api/invoice/` + this.$route.params.id,
      })
    );
    Promise.all(promises).then((responses) => {
      if (responses[0].status) {
        self.options.unitOptions = self.convertArrayToObjectByKey(
          responses[0].data,
          "id",
          "translations.0.name"
        );
      }
      if (responses[1].status) {
        self.options.companyOptions = self.convertArrayToObjectByKey(
          responses[1].data,
          "id",
          "name_for_accounting"
        );
      }
      if (responses[2].status) {
        self.options.currencyOptions = self.convertArrayToObjectByKey(
          responses[2].data,
          "id",
          "code"
        );
      }
      if (responses[3].status) {
        self.options.invoiceTypeOptions = self.convertArrayToObjectByKey(
          responses[3].data,
          "id",
          "translations.0.name"
        );
      }
      if (responses[4].status) {
        self.options.invoiceKindOptions = self.convertArrayToObjectByKey(
          responses[4].data,
          "id",
          "translations.0.name"
        );
      }
      if (responses[5].status) {
        self.options.receiveTypeOptions = self.convertArrayToObjectByKey(
          responses[5].data,
          "id",
          "translations.0.name"
        );
      }
      if (responses[6].status) {
        self.options.vatOptions = self.convertArrayToObjectByKey(
          responses[6].data,
          "id",
          "vat.rate"
        );
        self.options.vatOptionsRaw = responses[6].data;
      }
      if (responses[7].status) {
        self.item = responses[7].data;
        self.tmpItem = responses[7].data;
        if (self.item.invoice_history != null && self.item.invoice_history.length > 0) {
          self.item.invoice_history.forEach((history, index) => {
            self.item.invoice_history[index]["created_at"] = moment(
              self.item.invoice_history[index]["created_at"]
            ).format(MOMENT_SYSTEM_DATE_FORMAT);
          });
        }
        self.item.created_at = moment(self.item.created_at, LARAVEL_DATE_TIME_FORMAT);
        self.item.invoice_date = moment(self.item.invoice_date, LARAVEL_DATE_TIME_FORMAT);
      }
    });
  },
  methods: {
    findItem(id) {
      let index = _.findIndex(this.item.transactions, function (findItem) {
        return findItem.id == id;
      });
      return index + 1;
    },
    submitDividedProduct(index) {
      let item = _.cloneDeep(this.item.transactions[index]);
      item.divided_products.order = this.item.transactions[index].divided_products.orders[
        this.item.transactions[index].divided_products.order_id
      ];
      item.divided_products.fabric = this.item.transactions[
        index
      ].divided_products.orders[
        this.item.transactions[index].divided_products.order_id
      ].detail[this.item.transactions[index].divided_products.fabric_id];
      delete item.divided_products.orders;
      this.$store.commit(SET_LOADING, true);
      this.$store
        .dispatch(CREATE_ITEM, {
          url: `api/invoice/divider`,
          showLoading: false,
          contents: { transactions: item },
        })
        .then((response) => {
          if (response.status) {
            
            this.sweetAlertSuccess(this.$t("general.successfully_created")).then(() => {
              this.$store.commit(SET_LOADING, false);
              this.$router.go(-1);
            });
          } else {
            let data = response.data.response.data;
            this.sweetAlertError(this.$t(data.message));
          }
          this.sweetAlertClose();
        });
    },
    async getInvoiceList(index, instructionTypeId, supplier_company_id) {
      try {
        const response = await this.$store.dispatch(GET_ITEMS, {
          url: "api/waybill/waybill-order-option",
          filters: {
            is_invoice: 1,
            instruction_type_id: instructionTypeId,
            supplier_company_id: supplier_company_id,
            is_edit: 1,
          },
        });

        const data = response.data;

        return data;
      } catch (error) {
        console.log(error);
        throw error; // Hata işlenmediyse hatayı yeniden fırlatın
      }
    },
    cancelDividedProduct(index) {
      Vue.delete(this.item.transactions[index], "divided_products");
      this.sweetAlertSuccess(this.$t("general.successfully_deleted"));
    },
    async divideProduct(index) {
      let parrentTransaction = this.item.transactions[index];
      let dividedProduct = {
        order_id: null,
        orders: [],
        parent_amount: this.item.transactions[index].amount,
        transaction_amount: 0,
        parent_id: parrentTransaction.id,
        line_code: this.item.transactions[index].line_code,
        line_code_name: this.item.transactions[index].line_code_name,
        fabric_id: null,
      };
      this.$set(this.item.transactions[index], "divided_products", dividedProduct);
      let data = await this.getInvoiceList(
        this.item.transactions[index],
        this.item.transactions[index].instruction_type_id,
        this.item.supplier_company_id
      );
      this.$set(this.item.transactions[index].divided_products, "orders", data);
    },
  },
  watch: {
    "item.transactions": {
      handler: function (newValue, oldValue) {
        newValue.forEach((transaction, index) => {
          this.item.transactions[index].price =
            Number(transaction.amount) * transaction.unit_price;

          this.item.transactions[index].price_converted =
            Number(transaction.exchange_rate) *
            (Number(transaction.amount) * Number(transaction.unit_price_converted));

          let vat_rate = +transaction.vat_rate;

          let vat_rate_amount = (this.item.transactions[index].price * vat_rate) / 100;

          let vat_rate_amount_total =
            this.item.transactions[index].price + vat_rate_amount;

          this.item.transactions[index].total_price = vat_rate_amount_total;

          this.item.transactions[index].total_price_converted =
            Number(transaction.exchange_rate) * vat_rate_amount_total;

          this.item.transactions[index].vat_price = vat_rate_amount;

          this.item.transactions[index].vat_price_converted =
            Number(transaction.exchange_rate) * vat_rate_amount;
        });
      },
      deep: true,
    },
    "item.supplier_company_id": {
      handler: async function (newValue, oldValue) {
        if (newValue) {
          this.item.transactions.forEach((transaction, index) => {
            if (transaction.divided_products) {
              this.$store
                .dispatch(GET_ITEMS, {
                  url: "api/waybill/waybill-order-option",
                  filters: {
                    is_invoice: 1,
                    instruction_type_id: transaction.instruction_type_id,
                    supplier_company_id: this.item.supplier_company_id,
                    is_edit: 1,
                  },
                })
                .then((result) => {
                  if (result.status) {
                    const data = result.data;
                    this.$set(
                      this.item.transactions[index].divided_products,
                      "orders",
                      data
                    );
                  }
                });
            }
          });
        }
      },
      deep: true,
    },
  },
};
</script>
